@include normalize-version('Tables');

// ==========================================================================
//   Tables
// ==========================================================================

// Remove most spacing between table cells.

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
  padding: 0;
}