@include normalize-version('Typography');

// ==========================================================================
// Typography
// ==========================================================================

// Addresses styling not present in IE 8/9, Safari 5, and Chrome.

abbr[title] {
    border-bottom: 1px dotted;
}

// Addresses style set to `bolder` in Firefox 4+, Safari 5, and Chrome.

b,
strong {
    font-weight: bold;
}

// Addresses styling not present in Safari 5 and Chrome.

dfn {
    font-style: italic;
}

// Address variable `h1` font-size and margin within `section` and `article`
// contexts in Firefox 4+, Safari 5, and Chrome.

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

// Addresses styling not present in IE 8/9.

mark {
    background: #ff0;
    color: #000;
}

// Addresses inconsistent and variable font size in all browsers.

small {
    font-size: 80%;
}

// Prevents `sub` and `sup` affecting `line-height` in all browsers.

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}
