@include normalize-version('HTML5 Display Definitions');

// ==========================================================================
// HTML5 display definitions
// ==========================================================================

// Corrects `block` display not defined in IE 8/9.

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}

// Corrects `inline-block` display not defined in IE 8/9.

// 1. Correct `inline-block` display not defined in IE 8/9.
// 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.

audio,
canvas,
progress,
video {
    display: inline-block; // 1
    vertical-align: baseline; // 2
}

// Prevents modern browsers from displaying `audio` without controls.
// Remove excess height in iOS 5 devices.

audio:not([controls]) {
    display: none;
    height: 0;
}

// Addresses styling for `hidden` attribute not present in IE 8/9.

[hidden],
template {
    display: none;
}
