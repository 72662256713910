@include normalize-version('Embedded Content');

// ==========================================================================
// Embedded content
// ==========================================================================

// Removes border when inside `a` element in IE 8/9.

img {
    border: 0;
}

// Corrects overflow displayed oddly in IE 9.

svg:not(:root) {
    overflow: hidden;
}