@import 'base';
@import 'vendor/shelves/shelves-grid';

// ---------------------------------------------------------------------------
// Icon Fonts
// ---------------------------------------------------------------------------

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?atq1vo');
  src: url('../fonts/icomoon.eot?atq1vo#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?atq1vo') format('truetype'),
    url('../fonts/icomoon.woff?atq1vo') format('woff'),
    url('../fonts/icomoon.svg?atq1vo#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right:before {
  content: '\e900';
}
.icon-lock:before {
  content: '\e901';
}
.icon-facebook:before {
  content: '\e902';
}

// ---------------------------------------------------------------------------
// Base
// ---------------------------------------------------------------------------

html {
  background-color: #eaeaea;
  // background: url('../images/bg.jpg') no-repeat center 0 fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body {
  font-family: Helvetica, Arial, sans-serif;
}

a {
  transition-property: background-color border-color color;
  transition-timing-function: ease-out;
  transition-duration: $out-duration;
  color: $link-color;
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
    color: desaturate($link-color, 10%);
  }
  &:focus,
  &:hover {
    transition-duration: $in-duration;
    color: $secondary-color;
    cursor: pointer;
  }
  &:active {
    text-decoration: none;
    color: $link-color;
  }
}

.nobr {
  display: inline;
}

.no-wrap {
  white-space: nowrap;
}

h2 {
  font-family: $sans-serif;
  font-weight: 100;
  font-size: 28px;
  color: $secondary-color;
}

h3 {
  color: $secondary-color;
  text-transform: uppercase;
  font-family: $sans-serif;
  font-weight: 600;
  letter-spacing: 0.05em;
}

// ---------------------------------------------------------------------------
// Grid Tweaks
// ---------------------------------------------------------------------------

.row {
  @include on-mobile {
    padding-left: 0;
    padding-right: 0;
  }
}

@for $i from 1 to $shelves-tablet-columns {
  #{class-selector($shelves-tablet-column-name $i)} {
    margin-bottom: rhythm(1);
  }
}

@for $i from 1 to $shelves-desktop-columns {
  #{class-selector($shelves-desktop-column-name $i)} {
    margin-bottom: rhythm(1);
  }
}

@include on-mobile {
  @for $i from 1 to $shelves-mobile-columns {
    #{class-selector($shelves-mobile-column-name $i)} {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

@include on-tablet-up {
  @for $i from 1 to $shelves-tablet-columns {
    #{class-selector($shelves-tablet-column-name $i)} {
      margin-bottom: 0;
    }
  }
}

@include on-desktop {
  @for $i from 1 to $shelves-desktop-columns {
    #{class-selector($shelves-desktop-column-name $i)} {
      margin-bottom: 0;
    }
  }
}

// ---------------------------------------------------------------------------
// Header
// ---------------------------------------------------------------------------

.site-header {
  background: #f6f8f8 url('../images/header-bg2.png') no-repeat top center;
  padding: 5px 0;
  box-shadow: rgba(black, 0.2) 0px 5px 12px;
  position: relative;
  z-index: 999;

  @media (min-width: 730px) {
    padding: 0 0;
  }
}

.logo-container {
  position: relative;
}

.logo {
  text-align: center;

  @media (min-width: 730px) {
    padding-top: 10px;
  }
}

.fdic-ehl {
  position: absolute;
  right: 0;
  bottom: 10px;
  text-align: right;
  img {
    padding-right: 10px;
  }
}

// ---------------------------------------------------------------------------
// Navigation
// ---------------------------------------------------------------------------

.site-nav-container {
  background-color: $secondary-color;
  // background: rgba(255, 255, 255, 0.85);
  position: relative;
  z-index: 990;
}

.nav-list {
  text-align: center;
  font-family: $sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 0.09em;
  margin: 0;
  padding: 0;
  list-style: none;

  .nav-symbols {
    display: block;
    padding: 0 5px;
  }

  .nav-list-item {
    display: inline-block;

    > a {
      display: block;
      padding: 20px 0;
      line-height: 24px;
      height: 24px;

      span {
        display: block;
        padding: 0 20px;
        border-right: 1px solid #57cc57;
      }

      &:link {
        text-decoration: none;
        color: white;
      }
      &:visited {
        text-decoration: none;
        color: white;
      }
      &:focus,
      &:hover {
        transition-duration: $in-duration;
        color: $primary-color;
      }
      &:active {
        text-decoration: none;
        color: white;
      }
    }

    &:hover {
      > a {
        transition-duration: $in-duration;
        color: $primary-color;
      }
    }
  }

  .nav-list-submenu {
    font-family: Helvetica, sans-serif;
    font-weight: normal;
    text-transform: none;
    font-size: 15px;
    letter-spacing: 0;

    display: none;
    padding: rhythm(1, 15px) 0;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    background-color: #fff;
    z-index: 1;

    border-top: 8px solid $primary-color;
    box-shadow: 0px 5px 12px rgba(black, 0.2);
    transition: all 200ms;

    > ul {
      list-style: none;
      padding-left: 0;
    }

    .opacity & {
      display: block;
      max-height: 0;
      overflow: hidden;
      opacity: 0;
    }
  }

  .submenu-list {
    display: inline-block;
    width: 300px;
    vertical-align: top;
    text-align: left;

    ul {
      @extend .pretty-list;
    }
  }

  .nav-list-item-with-submenu:hover {
    > a {
      position: relative;
      &:before {
        position: absolute;
        top: 56px;
        left: 50%;
        border-color: transparent transparent $primary-color transparent;
        width: 0;
        height: 0;
        border-width: 8px;
        border-style: solid;
        content: '';
        margin-top: -8px;
        margin-left: -8px;
        z-index: 2;
      }
    }

    .nav-list-submenu {
      display: block;
      .opacity & {
        transition-duration: 500ms;
        opacity: 1;
        max-height: 350px;
        z-index: 995;
      }
    }
  }
}

.personal-page .nav-list .personal-nav-list-item,
.business-page .nav-list .business-nav-list-item,
.lending-page .nav-list .lending-nav-list-item,
.tools-page .nav-list .tools-nav-list-item,
.contact-page .nav-list .contact-nav-list-item {
  > a {
    color: $primary-color;
  }
}

.contact-nav-list-item {
  span {
    border-right: none;
  }
}

.login-nav-list-item {
  display: inline-block;

  .slicknav_menu & {
    display: none;
  }
}

.login-button {
  background-color: $primary-color;
  border: none;
  font-family: $sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  padding: 10px 15px;
  margin: 0 20px;
  letter-spacing: 0.1em;
  border-radius: 4px;
  font-size: 15px;

  &:link {
    text-decoration: none;
    color: #fff;
  }
  &:visited {
    text-decoration: none;
    color: #fff;
  }
  &:focus,
  &:hover {
    transition-duration: $in-duration;
    background-color: rgba($primary-color, 0.8);
  }
  &:active {
    text-decoration: none;
    color: $primary-color;
  }

  .slicknav_menu & {
    background-color: $secondary-color;
    float: left;
    margin: 5px;
    padding: 4px 12px;
  }
}

// ---------------------------------------------------------------------------
// Sidebar Navigation
// ---------------------------------------------------------------------------

.sidebar-nav {
  padding-top: 1em;

  h2 {
    margin-bottom: 0.4em;
  }

  > ul {
    list-style: none;
    padding-left: 0;

    ul {
      @extend .pretty-list;
      margin-bottom: 20px;
      li {
        border-bottom: 1px solid #b7b7b7;
        margin-top: 3px;
        margin-bottom: 3px;
      }
    }
  }
}

.selected a {
  color: $secondary-color;
}

// ---------------------------------------------------------------------------
// Site Body
// ---------------------------------------------------------------------------

.main {
  margin-top: 3em;
  padding-top: 3em;
  padding-bottom: 3em;
}

.white-background {
  background-color: white;
  background: rgba(255, 255, 255, 0.95);
  border: 1px solid $light-border-color;
  padding-left: 2em;
  padding-right: 2em;

  @include on-tablet-up {
    padding-left: 4em;
    padding-right: 4em;
  }

  @include screen($min-width: $shelves-max-width) {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.online-banking {
  background-color: $primary-color;
  color: #fff;
  padding: 5px 15px 20px 15px;
  font-size: 12px;
  margin: 0;

  h1 {
    font-family: $sans-serif;
    font-size: 18px;
    font-weight: 100;
    margin: 10px 0;
  }
}

article h1 {
  @include adjust-font-size-to(35px, 1.5);
  font-family: $lexia;
  font-style: italic;
  color: $secondary-color;
  font-weight: 300;
  border-bottom: 1px solid $light-border-color;
  padding-bottom: 0.5em;
}

article .h1-sub {
  font-family: $sans-serif;
  color: $primary-color;
  font-style: normal;
  font-weight: 400;
}

article ul {
  @extend .pretty-list;
}

.with-gallery ul {
  li {
    position: static;
    padding: 0;

    &:before {
      display: none;
    }
  }
}

@import 'modules/login-form';

.login-links {
  background-color: #196419;
  margin: 0;
  padding: 1px 12px;
  @include adjust-font-size-to(11px, 0.9);

  ul {
    list-style: none;
    padding-left: 0;
  }

  li:before {
    font-family: 'icomoon';
    content: '\e900';
    margin: 0 0 0 0;
    color: $secondary-color;
    font-size: 10px;
    position: relative;
    top: 1px;
  }
}

.online-banking,
.login-links {
  a {
    &:link {
      text-decoration: none;
      color: #fff;
      border-bottom: 1px solid #fff;
    }
    &:visited {
      text-decoration: none;
      color: desaturate(#fff, 10%);
      border-bottom: 1px solid #fff;
    }
    &:focus,
    &:hover {
      transition-duration: $in-duration;
      color: $secondary-color;
      border-bottom: 1px solid $secondary-color;
    }
    &:active {
      text-decoration: none;
      color: #fff;
      border-bottom: 1px solid $secondary-color;
    }
  }
}

h1.motto {
  @include adjust-font-size-to(35px, 2);
  font-family: $lexia;
  text-align: center;
  font-weight: 400;
  color: $primary-color;
  border-bottom: 1px solid $light-border-color;
  padding-bottom: 1em;
}

.page-intro {
  @include adjust-font-size-to(20px, 1.25);
  font-family: $lexia;
  font-weight: 300;
  color: $primary-color;

  img {
    max-width: 200px;
  }
}

.action-box {
  display: inline-block;
  background-color: $secondary-color;
  padding: 15px;
  margin: 0 0 1em 1em;
  color: #fff;
  float: right;
  font-family: $sans-serif;
  font-weight: 400;
  text-align: center;
  &:visited {
    color: #fff;
  }
  &:focus,
  &:hover {
    transition-duration: $in-duration;
    background-color: adjust-lightness($secondary-color, 10);
    cursor: pointer;
    color: #fff;
  }
}

.action-button {
  display: inline-block;
  background-color: $secondary-color;
  padding: 10px;
  margin: 0 0 0 0;
  color: #fff;
  float: none;
  font-family: $sans-serif;
  font-weight: 600;
  text-align: center;
  &:visited {
    color: #fff;
  }
  &:focus,
  &:hover {
    transition-duration: $in-duration;
    background-color: adjust-lightness($secondary-color, 10);
    cursor: pointer;
    color: #fff;
  }
}

.action-line1 {
  display: block;
  text-transform: uppercase;
  font-family: $condensed-sans-serif;
  font-weight: 700;
  @include adjust-font-size-to(35px, 1.25);
}

.action-line2 {
  display: block;
  font-family: $lexia;
  font-weight: 400;
  font-style: italic;
  @include adjust-font-size-to(30px, 1.5);
}

.action-line3 {
  color: $primary-color;
  border-bottom: 1px solid $primary-color;
}

.motto em {
  color: $secondary-color;
}

.pretty-list {
  margin-top: 0;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    padding-bottom: 5px;
    padding-left: 20px;

    &:before {
      font-family: 'icomoon';
      content: '\e900';
      margin: 0 0 0 0;
      color: $secondary-color;
      font-size: 20px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.blocks {
  @include on-desktop {
    // text-align: center;
  }
}

.row-1 {
  display: flex;
  // gap: 80px;
  padding-bottom: 40px;
  justify-content: space-between;
}

.contact-block,
.locations-block {
  width: 100%;
  h2 {
    padding: 10px 0;
    margin: 0;
  }

  h3 {
    margin-bottom: 0;
  }

  @include on-tablet-up {
    padding-left: 75px;
    width: 50%;
  }

  @include on-desktop {
    // display: inline-block;
    text-align: left;
    vertical-align: top;
  }
}

.contact-block {
  ul {
    @extend .pretty-list;
  }

  @include on-tablet-up {
    background: url('../images/contact-icon.png') no-repeat 35px 5px;
  }

  @include on-desktop {
    // width: 25%;
    padding-left: 40px;
    background-position: 0 5px;
  }
}

.locations-block {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  @include on-mobile {
    margin-top: rhythm(1);
  }

  @include on-tablet-up {
    background: url('../images/location-icon.png') no-repeat 40px 1px;
  }

  @include on-desktop {
    // width: 330px;
  }

  .location-name {
    display: block;
    text-transform: uppercase;
    font-family: $sans-serif;
    font-weight: 600;
    letter-spacing: 0.05em;
    font-size-adjust: 12px;
  }
}

.locations-list {
  list-style: none;
}

.locations-list li {
  float: left;
  margin-right: 2em;
  margin-bottom: 1em;
}

.cec,
.quick-links {
  li:before {
    font-family: 'icomoon';
    content: '\e900';
    margin: 0 0 0 -15px;
    color: $secondary-color;
    font-size: 29px;
    position: relative;
    top: 6px;
  }
}

.cta-button {
  background-color: $secondary-color;
  color: #fff;
  font-family: $sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  padding: 12px;
  letter-spacing: 0.1em;
  border-radius: 4px;

  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
    color: desaturate(#fff, 10%);
  }
  &:focus,
  &:hover {
    transition-duration: $in-duration;
    background-color: adjust-lightness($secondary-color, -10);
  }
  &:active {
    text-decoration: none;
    color: #fff;
  }
}

.contact-info {
  h3 {
    margin-bottom: 0;
    padding-bottom: 0;
    display: inline;
  }

  p {
    margin-top: 0;
  }
}

.mobile-app-links {
  margin: 0 auto;
  text-align: center;
  width: 50%;
}

.row-2 {
  display: flex;
  gap: 80px;
  h2 {
    text-align: CENTER;
    line-height: 1.2em;
  }
  .zelle-links {
    width: 50%;
  }
  p {
    text-align: center;
    padding-bottom: 1em;
  }
}

@media screen and (max-width: 740px) {
  .row-1 {
    padding-bottom: 0;
  }
  .row-1, .row-2 {
    gap: 20px;
    flex-wrap: wrap;
  }
  .mobile-app-links, .contact-block, .locations-block {
    width: 100%;
  }
  .zelle-links {
    width: 100% !important;
  }
}

.learn-more-btn {
  background-color: $secondary-color;
  color: white;
  padding: 14px;
  border-radius: .5em;
  &:focus,
  &:hover {
    transition-duration: $in-duration;
    background-color: #398f39;
    color: white;
  }
  &:visited {
    color: white;
  }
}

// ---------------------------------------------------------------------------
// Forms
// ---------------------------------------------------------------------------

input[type='text'],
input[type='password'],
input[type='email'] {
  box-sizing: border-box;
  width: 75%;
  border: 1px solid #adadad;
  height: 28px;
  padding: 3px 5px;
  margin-bottom: 1em;
  font-weight: normal;
  color: $black;
  border-radius: 4px;
  @include adjust-font-size-to(14px, 1);
}

input.login-button {
  background-color: $secondary-color;
  border: none;
  color: #fff;
  font-family: $sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  padding: 7px 10px 7px 10px;
  letter-spacing: 0.1em;
  border-radius: 4px;
  margin-top: 1em;
  font-size: 14px;

  &:focus,
  &:hover {
    transition-duration: $in-duration;
    background-color: adjust-lightness($secondary-color, -10);
  }
}

// ---------------------------------------------------------------------------
// Tables
// ---------------------------------------------------------------------------

table {
  th,
  td {
    padding: 10px;
    text-align: left;
    border: 1px solid #b3b3b3;
  }
}

.table-intro {
  font-family: $sans-serif;
  @include adjust-font-size-to(22px, 1.2);
  font-weight: 300;
}

.comparison-table-wrapper {
  overflow-x: auto;

  table {
    width: 100%;
    font: 12px Arial, sans-serif;
    table-layout: fixed;

    th {
      background-color: #f1f2f2;
      font-family: $sans-serif;
      color: $secondary-color;
      font-weight: 600;

      @include on-tablet-up {
        @include adjust-font-size-to(13px, 0.8, $from-size: 12px);
      }
    }

    @include on-mobile {
      @include adjust-font-size-to(10px, 0.5);
    }

    th,
    td {
      padding: 10px;
      text-align: left;
    }
  }
}

.privacy-policy-table {
  table#privacy_policy_part1 {
    font-family: Arial, sans-serif;
    font-size: 12px;
    background-color: #fff;
    border: none;
    border: 1px solid #b4b4b4;
  }

  table#privacy_policy_part2,
  table#privacy_policy_part3,
  table#privacy_policy_part4 {
    margin-top: 10px;
    font-family: Arial, sans-serif;
    font-size: 12px;
    background-color: #fff;
    border: none;
  }

  table#privacy_policy_part3 {
    border: 1px solid #b4b4b4;
  }

  th#title1 {
    color: #fff;
    background-color: #58585a;
    font-size: 23px;
    padding: 12px;
  }

  th#title2 {
    padding: 5px 0 0 10px;
    text-transform: uppercase;
    border-bottom: 1px solid #b4b4b4;
    vertical-align: top;
    font-weight: bold;
  }

  tr#headers_part2 {
    color: #fff;
    background-color: #9d9d9d;
    font-weight: bold;
    font-size: 12px;
  }

  tr#headers_part4 {
    color: #fff;
    background-color: #9d9d9d;
    font-weight: bold;
    font-size: 15px;
  }

  tr#headers_part2 th,
  tr#headers_part4 th {
    padding: 5px 0 5px 12px;
  }

  td.rowtitle,
  th.rowtitle {
    color: #fff;
    background-color: #9d9d9d;
    padding: 12px 30px 0 12px;
    font-weight: bold;
    vertical-align: text-top;
  }

  table#privacy_policy_part1 td.rowinfo,
  table#privacy_policy_part3 td.rowinfo,
  table#privacy_policy_part3 td.rowinfo {
    font-size: 11px;
    padding: 12px 12px 12px 8px;
    border-bottom: 1px solid #b4b4b4;
  }

  table#privacy_policy_part2 td.rowinfo,
  table#privacy_policy_part4 td.rowinfo {
    font-size: 11px;
    padding: 12px 12px 12px 8px;
    border-bottom: 1px solid #b4b4b4;
    border-right: 1px solid #b4b4b4;
  }

  .privacy-policy th {
    text-align: left;
  }

  .border-left {
    border-left: 1px solid #b4b4b4;
  }
}
// ---------------------------------------------------------------------------
// Footer
// ---------------------------------------------------------------------------

.footer {
  color: #383838;
  font-family: sans-serif;
  font-weight: 300;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: none;
  margin-bottom: 3em;
}

.icon-facebook {
  color: #4576da;
  @include adjust-font-size-to(25px, 0);
  position: relative;
  top: 6px;
}

.footer-info {
}

.footer-right {
  text-align: right;

  @include on-mobile {
    text-align: left;
  }
}

// ---------------------------------------------------------------------------
// Banners
// ---------------------------------------------------------------------------

@import 'vendor/flexslider';

// ---------------------------------------------------------------------------
// Slicknav
// ---------------------------------------------------------------------------

@import 'vendor/slicknav';

.slicknav_menu {
  display: none;

  .nav-symbols {
    padding: 7px 15px;
    display: none;
  }
}

// ---------------------------------------------------------------------------
// Gallery
// ---------------------------------------------------------------------------

@import 'vendor/fancybox';

.gallery {
  @include pie-clearfix;
  list-style: none;
  padding: 0;
  margin-left: -3.3333%;
}

.gallery-item {
  float: left;
  width: 30%;
  margin: 0 0 3.3333% 3.3333%;

  img {
    display: block;
  }
}

.full-gallery {
  @include on-desktop {
    margin-left: -1.6666%;

    .gallery-item {
      width: 15%;
      margin: 0 0 1.6666% 1.6666%;
    }
  }
}

.community-gallery {
  ul li {
    list-style: none;
  }
  ul {
    list-style: none;
  }
}

// ---------------------------------------------------------------------------
// Modals
// ---------------------------------------------------------------------------

@import 'vendor/remodal';
@import 'vendor/remodal-theme';

// .remodal-bg.remodal-is-opening,
// .remodal-bg.remodal-is-opened {
//   -webkit-filter: none;
//   filter: none;
// }

.remodal-overlay {
  background: rgba(white, 0.95);
}

.remodal,
.remodal-wrapper:after {
  vertical-align: top;
}

.remodal {
  text-align: left;
}

// ---------------------------------------------------------------------------
// Responsive
// ---------------------------------------------------------------------------

@media screen and (max-width: 940px) {
  /* #menu is the original menu */
  #menu {
    display: none;
  }

  .slicknav_menu {
    display: block;
  }

  .nav-list-submenu {
    position: static;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width: 0) and (max-width: 625px) {
  .flexslider {
    margin-top: 2em;
  }

  .cta-button {
    font-size: 10px;
  }

  #login-form {
    label {
      width: 30%;
    }
    .forgot-password {
      padding-left: 0;
    }
    .login {
      padding-left: 0;
    }
  }

  h1.motto {
    @include adjust-font-size-to(28px, 1.75);
  }

  .main {
    margin-top: 0;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-width: 626px) and (max-width: 798px) {
  .flexslider {
    margin-top: 2em;
  }
}

/* iPad Landscape and small Dispalys ----------- */
@media only screen and (min-width: 799px) and (max-width: 1023px) {
  .cta-button {
    font-size: 10px;
  }

  #login-form {
    label {
      width: 30%;
    }
    .forgot-password {
      padding-left: 0;
    }
    .login {
      padding-left: 0;
    }
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
}
