@include normalize-version('Base');

// ==========================================================================
// Base
// ==========================================================================

// 1. Sets default font family to sans-serif.
// 2. Prevents iOS text size adjust after orientation change, without disabling
//    user zoom.

html {
    font-family: sans-serif; // 1
    -webkit-text-size-adjust: 100%; // 2
    -ms-text-size-adjust: 100%; // 2
}

// Removes default margin.

body {
    margin: 0;
}
