/*
    Mobile Menu Core Style
*/

.slicknav_btn { position: relative; display: block; vertical-align: middle; float: right; padding: 9px 12px 5px; line-height: 1.125em; cursor: pointer; }
.slicknav_menu  .slicknav_menutxt { display: block; line-height: 1.188em; float: left; }
.slicknav_menu .slicknav_icon { float: left; margin: 1px 0 0 10px; }
.slicknav_menu .slicknav_no-text { margin: 0 }
.slicknav_menu .slicknav_icon-bar { display: block; width: 1.125em; height: 0.125em; -webkit-border-radius: 1px; -moz-border-radius: 1px; border-radius: 1px; -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25); -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25); box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25); }
.slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar { margin-top: 0.188em }
.slicknav_nav { clear: both }
.slicknav_nav ul,
.slicknav_nav li { display: block }
.slicknav_nav .slicknav_arrow { font-size: 0.8em; margin: 0 0 0 0.4em; }
.slicknav_nav .slicknav_item { cursor: pointer; }
.slicknav_nav .slicknav_row { display: block; }
.slicknav_nav a { display: block }
.slicknav_nav .slicknav_item a,
.slicknav_nav .slicknav_parent-link a { display: inline }
.slicknav_menu:before,
.slicknav_menu:after { content: " "; display: table; }
.slicknav_menu:after { clear: both }
/* IE6/7 support */
.slicknav_menu { *zoom: 1 }

/*
    User Default Style
    Change the following styles to modify the appearance of the menu.
*/

.slicknav_menu {
    font-family: $sans-serif;
    font-weight: 400;
    font-size:16px;
}
/* Button */
.slicknav_btn {
    margin: 5px 5px 6px;
    padding-bottom: 9px;
    text-decoration:none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: $secondary-color;
}
/* Button Text */
.slicknav_menu  .slicknav_menutxt {
    color: #FFF;
}
/* Button Lines */
.slicknav_menu .slicknav_icon-bar {
  background-color: #f5f5f5;
}
.slicknav_menu {
    background:$primary-color;
    padding:5px;
}
.slicknav_nav {
    color:#fff;
    margin:0;
    padding:0;
    font-size:1em;
    font-family: $sans-serif;
    font-weight: 400;
}
.nav-list-item {
  text-transform: uppercase;
  letter-spacing: .05em;
}
.slicknav_nav, .slicknav_nav ul {
    list-style: none;
    overflow:hidden;
}
.slicknav_nav ul {
    padding:0;
    margin:0 0 0 20px;
}
.slicknav_nav .slicknav_row {
    padding:5px 10px;
    margin:2px 5px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}
.slicknav_nav a {
    padding:5px 10px;
    margin:2px 5px;
    text-decoration:none;
    color:#fff;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}
.slicknav_nav .slicknav_item a,
.slicknav_nav .slicknav_item h3,
.slicknav_nav .slicknav_parent-link a {
    padding:0;
    margin:0;
}
.slicknav_nav .slicknav_row:hover {
    background:$secondary-color;
}
.slicknav_nav a:hover{
    background:$secondary-color;
}
.slicknav_nav .slicknav_txtnode {
     margin-left:15px;
}
.slicknav_nav .slicknav_item h3 {
  display: inline;
  color: #fff;
  text-transform: none;
  letter-spacing: 0;
  font-size: 1em;
  font-weight: normal;
}
