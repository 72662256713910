@include normalize-version('Forms');

// ==========================================================================
// Forms
// ==========================================================================

// Known limitation: by default, Chrome and Safari on OS X allow very limited
// styling of `select`, unless a `border` property is set.

// 1. Correct color not being inherited.
//    Known issue: affects color of disabled elements.
// 2. Correct font properties not being inherited.
// 3. Address margins set differently in Firefox 4+, Safari 5, and Chrome.

button,
input,
optgroup,
select,
textarea {
  color: inherit; // 1
  font: inherit; // 2
  margin: 0; // 3
}

// Address `overflow` set to `hidden` in IE 8/9/10.

button {
  overflow: visible;
}

// Address inconsistent `text-transform` inheritance for `button` and `select`.
// All other form control elements do not inherit `text-transform` values.
// Correct `button` style inheritance in Firefox, IE 8+, and Opera
// Correct `select` style inheritance in Firefox.

button,
select {
  text-transform: none;
}

// 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
//    and `video` controls.
// 2. Correct inability to style clickable `input` types in iOS.
// 3. Improve usability and consistency of cursor style between image-type
//    `input` and others.

button,
html input[type="button"], // 1
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; // 2
  cursor: pointer; // 3
}

// Re-set default cursor for disabled elements.

button[disabled],
html input[disabled] {
  cursor: default;
}

// Remove inner padding and border in Firefox 4+.

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

// Address Firefox 4+ setting `line-height` on `input` using `!important` in
// the UA stylesheet.

input {
  line-height: normal;
}

// It's recommended that you don't attempt to style these elements.
// Firefox's implementation doesn't respect box-sizing, padding, or width.
//
// 1. Address box sizing set to `content-box` in IE 8/9/10.
// 2. Remove excess padding in IE 8/9/10.

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; // 1
  padding: 0; // 2
}

// Fix the cursor style for Chrome's increment/decrement buttons. For certain
// `font-size` values of the `input`, it causes the cursor style of the
// decrement button to change from `default` to `text`.

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

// 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
// 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
//    (include `-moz` to future-proof).

input[type="search"] {
  -webkit-appearance: textfield; // 1
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; // 2
  box-sizing: content-box;
}

// Remove inner padding and search cancel button in Safari and Chrome on OS X.
// Safari (but not Chrome) clips the cancel button when the search input has
// padding (and `textfield` appearance).

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

// Define consistent border, margin, and padding.

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

// 1. Correct `color` not being inherited in IE 8/9.
// 2. Remove padding so people aren't caught out if they zero out fieldsets.

legend {
  border: 0; // 1
  padding: 0; // 2
}

// Remove default vertical scrollbar in IE 8/9.

textarea {
  overflow: auto;
}

// Don't inherit the `font-weight` (applied by a rule above).
// NOTE: the default cannot safely be changed in Chrome and Safari on OS X.

optgroup {
  font-weight: bold;
}
