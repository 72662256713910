// Default Grid Settings
$shelves-width: 1254px;
$shelves-columns: 12;
$shelves-margin: 40px;
$shelves-gutter: 40px;
$shelves-tablet-breakpoint: 800px;
$shelves-mobile-breakpoint: 480px;

// Typography
$base-font-size: 14px;
$base-line-height: 22px;
$base-font-family: Helvetica, Arial, sans-serif;
$indent-amount: 20px;
$sans-serif: "proxima-nova", sans-serif;
$condensed-sans-serif: "proxima-nova-alt-condensed", sans-serif;
$lexia: "lexia",serif;
$adelle: "adelle",serif;

// Colors
$background-color: white;
$text-color: #323232;
$primary-color: #003b00;
$secondary-color: #48b348;
$black: #373d3b;
$link-color: #508294;
$border-color: #a5a5a5;
$light-border-color: #b6b6b6;

// Animations
$in-duration: 0.2s !default;
$out-duration: 0.5s !default;
$default-transition-property: all !default;
$default-transition-duration: $out-duration !default;
$default-transition-function: ease-out !default;
