.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

#login-form {
  @include adjust-font-size-to(11px, 0.9);
  font-weight: normal;
  width: 90%;

  .icon-lock {
    @include adjust-font-size-to(34px, 0);
    color: #fff;
    float: left;
    height: 30px;
    margin: 0 0.5em 0 -1.5em;
  }

  label {
    display: inline-block;
    width: 20%;
  }

  .forgot-password {
    margin: 0;
    padding-left: 20%;
  }

  .login {
    padding-left: 20%;
    margin-top: 1em;
  }
}

.login-links {
  background-color: #196419;
  margin: 0;
  padding: 1px 12px;
  @include adjust-font-size-to(11px, 0.9);

  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0.5em;
  }

  .icon {
    font-size: 1.5em;
    color: $secondary-color;
    position: relative;
    top: 0.25em;
  }
}

.online-banking,
.login-links {
  a {
    &:link {
      text-decoration: none;
      color: #fff;
      border-bottom: 1px solid #fff;
    }
    &:visited {
      text-decoration: none;
      color: desaturate(#fff, 10%);
      border-bottom: 1px solid #fff;
    }
    &:focus,
    &:hover {
      transition-duration: $in-duration;
      color: $secondary-color;
      border-bottom: 1px solid $secondary-color;
    }
    &:active {
      text-decoration: none;
      color: #fff;
      border-bottom: 1px solid $secondary-color;
    }
  }
}

.online-banking {
  background-color: $primary-color;
  color: #fff;
  padding: 5px 15px 20px 15px;
  font-size: 12px;
  margin: 0;

  h1 {
    font-family: $sans-serif;
    font-size: 18px;
    font-weight: 100;
    margin: 10px 0;
  }
}

// ---------------------------------------------------------------------------
// Forms
// ---------------------------------------------------------------------------

input[type='text'],
input[type='password'],
input[type='email'] {
  box-sizing: border-box;
  width: 75%;
  border: 1px solid #adadad;
  height: 28px;
  padding: 3px 5px;
  margin-bottom: 1em;
  font-weight: normal;
  color: $black;
  border-radius: 4px;
  @include adjust-font-size-to(14px, 1);
}

input.login-button {
  background-color: $secondary-color;
  border: none;
  color: #fff;
  font-family: $sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  padding: 7px 10px 7px 10px;
  letter-spacing: 0.1em;
  border-radius: 4px;
  font-size: 14px;

  &:focus,
  &:hover {
    transition-duration: $in-duration;
    background-color: adjust-lightness($secondary-color, -10);
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (max-width: 320px) {
  #login-form {
    label {
      width: 30%;
    }
    .forgot-password {
      padding-left: 0;
    }
    .login {
      padding-left: 0;
    }
    .icon-lock {
      margin-left: 0;
    }
  }
}
